<template>
  <div>
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item active>行銷應用中心</b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'RewardCardList' }">集點卡管理</b-breadcrumb-item>
          <b-breadcrumb-item active>集點獎項</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <b-card>
      <h4 class="font-weight-bold mb-3">{{ pageTitle }}</h4>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <b-button
          v-if="checkPermission([consts.REWARD_GIFT_CREATE])"
          class="flex-shrink-0 mr-2"
          variant="inverse-primary"
          v-b-modal.modal-search-product
          :disabled="showLoading"
        ><i class="fa fa-plus"></i>選擇商品</b-button>
        <b-button
          v-if="checkPermission([consts.REWARD_GIFT_MODIFY])"
          @click="submit"
          class="flex-shrink-0"
          variant="success"
          :disabled="showLoading"
        >儲存</b-button>
      </div>
      <RewardGiftListProductSelection
        :merchant-id="merchantId"
        :selected-products="selectedProducts"
        @selected="handleUpdateSelectedProducts"
      />

      <b-card-text>
        <b-overlay :show="showLoading" rounded="sm">
          <b-table striped hover responsive :items="rewardGifts" :fields="fields">
            <template #cell(is_enabled)="data">
              <b-form-checkbox v-model="data.item.is_enabled" :value="1" :disabled="isReadOnly"></b-form-checkbox>
            </template>
            <template #cell(title)="data">
              <router-link :to="{ name: 'ProductDetail', params: { product_id: data.item.target_id } }">
                <div>{{ deepGet(data, 'item.target.title') }}</div>
                <div>{{ deepGet(data, 'item.target.sale_page_code') }}</div>
              </router-link>
            </template>
            <template #cell(image)="data">
              <b-img-lazy
                v-if="deepGet(data, 'item.target.images[0].pic_url')"
                class="thumb-image"
                :src="deepGet(data, 'item.target.images[0].pic_url')"
                alt="商品圖片"
                width="50"
                height="50"
              />
            </template>
            <template #cell(point_need)="data">
              <b-form-input
                type="text"
                v-model="data.item.point_need"
                :readonly="isReadOnly"
                style="width: 100px"
              ></b-form-input>
            </template>
            <template #cell(actions)="data">
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_GIFT_DELETE])"
                variant="inverse-danger"
                @click="deleteRewardGift(data.item, data)"
              >
                <span class="mdi mdi-delete"></span> 移除
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import rewardCardApi from "@/apis/reward-card";
import rewardGiftApi from "@/apis/reward-gift";
import RewardGiftListProductSelection from "./RewardGiftListProductSelection";
import * as consts from "@/consts";
import sweetAlertPresets from "@/mixins/Dashboard/sweetAlertPresets";
import deepGet from "@/utils/deepGet";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  components: {
    RewardGiftListProductSelection,
  },
  mixins: [
    sweetAlertPresets,
  ],
  data() {
    return {
      consts: consts,
      showLoading: true,
      rewardCard: null,
      rewardGifts: [],
      fields: [
        { key: "is_enabled", label: "啟用" },
        { key: "title", label: "商品名稱/編號" },
        { key: "image", label: "商品圖片" },
        { key: "point_need", label: "所需點數" },
        { key: "actions", label: "管理" },
      ],
      candidateIsFetched: false,
    };
  },
  computed: {
    rewardCardId() {
      return this.$route.params.reward_card_id;
    },
    merchantId() {
      return this.rewardCard?.merchant_id;
    },
    isReadOnly() {
      return false === this.checkPermission([consts.REWARD_GIFT_MODIFY]);
    },
    pageTitle() {
      const verb = this.isReadOnly ? "查看" : "編輯";
      return this.rewardCard ? `【${this.rewardCard.title}】${verb}集點獎項` : `${verb}集點獎項`;
    },
    selectedProducts() {
      return this.rewardGifts.filter(gift => gift.target_type == 'App\\Models\\Product').map(gift => gift.target);
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchRewardCard(),
      this.fetchRewardGifts(),
    ]);
    this.showLoading = false;
  },
  methods: {
    deepGet,
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchRewardCard() {
      const { data } = await rewardCardApi.get(this.rewardCardId);

      this.rewardCard = data.data;
    },
    async fetchRewardGifts() {
      try {
        const response = await rewardGiftApi.list(this.rewardCardId, {});
        this.rewardGifts = response.data.data;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取集點獎項列表失敗", "error");
      }
    },
    async submit() {
      this.showLoading = true;

      try {
        await rewardGiftApi.updateAll(this.rewardCardId, { gifts: this.rewardGifts });
        this.$swal.fire({
          title: "更新成功",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取集點獎項列表失敗", "error");
      }

      this.showLoading = false;
    },
    async deleteRewardGift(rewardGiftRowData) {
      const confirmation = await this.confirmDeletion({
        text: '按下儲存之後才會確實刪除',
        title: '確定要移除嗎？',
        confirmButtonText: "確定移除",
        cancelButtonText: "不要移除",
      });
      if (false === confirmation) {
        return;
      }

      this.rewardGifts.splice(rewardGiftRowData.index, 1);
    },
    dateFormatter: (value) => {
      if (!value) return "無";
      return format(new Date(value), "yyyy-MM-dd");
    },
    handleUpdateSelectedProducts(products) {
      const giftIsStillSelected = gift => products.some(({ id }) => id === gift.target_id);
      this.rewardGifts = this.rewardGifts.filter(giftIsStillSelected);

      const productIsNewlySelected = product => false === this.rewardGifts.some(gift => gift.target_id == product.id);
      products
        .filter(productIsNewlySelected)
        .forEach(product => {
          this.rewardGifts.push({
            target_id: product.id,
            target_type: 'App\\Models\\Product',
            point_need: 0,
            is_enabled: false,
            target: product,
          });
        });
    },
  },
};
</script>
